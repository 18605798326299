$(window).scroll(function () {
  let stickyHeader = $('.js-sticky-header');
  let stickyHeaderHeight = stickyHeader.outerHeight();

  if ($(this).scrollTop() > 1) {
    stickyHeader.addClass("js-sticky-header--sticky");
    stickyHeader.css('top', '0px')
  } else {
    stickyHeader.removeClass("js-sticky-header--sticky");
    stickyHeader.css('top', '-' + stickyHeaderHeight + 'px')
  }
});


// мобильная липкая шапка
$(window).scroll(function () {
  let stickyHeader = $('.js-mobile-sticky-header');
  let stickyHeaderHeight = stickyHeader.outerHeight();

  if ($(this).scrollTop() > 1) {
    stickyHeader.addClass("js-mobile-sticky-header--sticky");
    stickyHeader.css('top', '0px')
  } else {
    stickyHeader.removeClass("js-mobile-sticky-header--sticky");
    stickyHeader.css('top', '-' + stickyHeaderHeight + 'px')
  }
});